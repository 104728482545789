import React, { useEffect, useState } from "react";
import { useNavigation } from "react-router-dom";
import cc from "classcat";
import { twMerge } from "tailwind-merge";
import { useDispatch, useSelector } from "react-redux";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { DropdownMenuSeparator } from "@radix-ui/react-dropdown-menu";
// components
import Dropdown from "../uikit/Dropdown/Dropdown";
import DropdownTrigger from "../uikit/Dropdown/DropdownTrigger";
import DropdownContent from "../uikit/Dropdown/DropdownContent";
import DropdownItem from "../uikit/Dropdown/DropdownItem";
import Trans from "../intl/Trans";
import NavLink from "../../vendor/router/NavLink";
import Link from "../../vendor/router/Link";
// redux
import { authActions, authSelectors } from "../../redux/auth";
// custom hooks
import useNavigate from "../../vendor/router/useNavigate";
import { useApplicationLoaderContext } from "./ApplicationLoaderContext";
// defs
import routes from "../router/routes";
// resources
import Logo from "./../../../resources/images/logo/mark_main_dark.svg";

export default function Navbar() {
    /**
     * --------------------
     * Hooks
     * --------------------
     */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { setIsPageLoaderShown } = useApplicationLoaderContext();

    /**
     * --------------------
     * Selectors
     * --------------------
     */
    const fname = useSelector(authSelectors.fname);
    const lname = useSelector(authSelectors.lname);
    const email = useSelector(authSelectors.email);

    /**
     * --------------------
     * Mobile menu state
     * --------------------
     */
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    /**
     * --------------------
     * Effect to close the menu when the route is changed
     * --------------------
     */
    useEffect(() => {
        if (isMobileMenuVisible) {
            setIsMobileMenuVisible(false);
        }
    }, [navigation.state]);

    /**
     * ----
     * The navbar menu items
     * ----
     */
    const navbarElements = [
        {
            id: 1,
            label: <Trans>Link-uri de plată</Trans>,
            to: routes.PAYMENT_LINKS,
        },
        {
            id: 2,
            label: <Trans>Clienți</Trans>,
            to: routes.CLIENTS,
        },
        {
            id: 3,
            label: <Trans>Conturile mele bancare</Trans>,
            to: routes.MY_BANKS_ACCOUNTS,
        },
        {
            id: 4,
            label: <Trans>Șabloane pagini de plată</Trans>,
            to: routes.TEMPLATES,
        },
        {
            id: 5,
            label: <Trans>Setări</Trans>,
            to: routes.SETTINGS,
        },
    ];

    /**
     * --------------------
     * Mobile bottom menu items
     * --------------------
     */
    const mobileBottomMenuElements = [
        {
            id: 1,
            label: <Trans>Contul meu</Trans>,
            to: routes.MY_ACCOUNT,
        },
    ];

    /**
     * --------------------
     * Handle logout action
     * --------------------
     */
    function onLogout() {
        /**
         * We trigger the logout api but we don't wait for it since it is not a critical flow.
         */
        setIsPageLoaderShown(true);
        dispatch(authActions.logoutApi());
    }

    /**
     * --------------------
     * Mobile bottom actions handlers
     * --------------------
     */
    function onGoToMyAccount() {
        navigate(routes.MY_ACCOUNT);
    }

    /**
     * --------------------
     * Render
     * --------------------
     */
    return (
        <nav className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                        <Link to={routes.PAYMENT_LINKS} className="flex-shrink-0">
                            <Logo className="h-8 w-8" />
                        </Link>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {navbarElements.map((item) => {
                                    return (
                                        <NavLink
                                            key={item.id}
                                            to={item.to}
                                            className={({ isActive }) =>
                                                twMerge(
                                                    cc([
                                                        "rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white",
                                                        {
                                                            "is-active bg-gray-900": isActive,
                                                        },
                                                    ]),
                                                )
                                            }>
                                            <div>{item.label}</div>
                                        </NavLink>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            {/*Profile dropdown*/}
                            <div className="relative ml-3">
                                <Dropdown>
                                    <DropdownTrigger className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <button aria-expanded="false" aria-haspopup="true">
                                            <UserCircleIcon className="h-8 w-8 rounded-full fill-white transition-all duration-300 hover:fill-gray-300 group-focus:fill-white group-data-[state=open]:fill-white " />
                                        </button>
                                    </DropdownTrigger>
                                    <DropdownContent side="bottom" align="end">
                                        <DropdownItem className="hover:bg-gray-100" onSelect={onGoToMyAccount}>
                                            <Trans>Contul meu</Trans>
                                        </DropdownItem>
                                        <DropdownMenuSeparator className="mx-3 my-1 border-t border-gray-200" />
                                        <DropdownItem className="hover:bg-gray-100" onSelect={onLogout}>
                                            <Trans>Deconectează-te</Trans>
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        {/*Mobile menu button*/}
                        <button
                            disabled={navigation.state === "loading"}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className={cc([
                                    "h-6 w-6",
                                    {
                                        block: !isMobileMenuVisible,
                                        hidden: isMobileMenuVisible,
                                    },
                                ])}
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                />
                            </svg>
                            <svg
                                className={cc([
                                    "h-6 w-6",
                                    {
                                        hidden: !isMobileMenuVisible,
                                        block: isMobileMenuVisible,
                                    },
                                ])}
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {isMobileMenuVisible && (
                <div className="md:hidden" id="mobile-menu">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {navbarElements.map((item) => {
                            return (
                                <NavLink
                                    key={item.id}
                                    to={item.to}
                                    className={({ isActive }) =>
                                        twMerge(
                                            cc([
                                                "block rounded-md px-3 py-2 text-base font-medium text-gray-300  hover:text-white",
                                                {
                                                    "bg-gray-900 text-white": isActive,
                                                },
                                            ]),
                                        )
                                    }>
                                    <div>{item.label}</div>
                                </NavLink>
                            );
                        })}
                    </div>
                    <div className="border-t border-gray-700 pb-3 pt-4">
                        <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                                <UserCircleIcon className="h-10 w-10 rounded-full fill-white transition-all duration-300 hover:fill-gray-300 group-focus:fill-white group-data-[state=open]:fill-white " />
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium leading-none text-white">
                                    {fname}&nbsp;{lname}
                                </div>
                                <div className="text-sm font-medium leading-none text-gray-400">{email}</div>
                            </div>
                        </div>
                        <div className="mt-3 space-y-1 px-2">
                            {mobileBottomMenuElements.map((item) => {
                                return (
                                    <NavLink
                                        key={item.id}
                                        to={item.to}
                                        className={({ isActive }) =>
                                            twMerge(
                                                cc([
                                                    "block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white",
                                                    {
                                                        "bg-gray-900 text-white": isActive,
                                                    },
                                                ]),
                                            )
                                        }>
                                        <div>{item.label}</div>
                                    </NavLink>
                                );
                            })}
                            <button
                                onClick={onLogout}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                aria-controls="mobile-menu"
                                aria-expanded="false">
                                <Trans>Deconectează-te</Trans>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
}
