/**
 * !!!!!!!!
 * IMPORTANT: DO NOT FORGET TO ADD / REMOVE / EDIT ROUTES HERE IN BOTH PLACES
 * !!!!!!!!
 */

export const routesParts = {
    // Auth routes
    AUTH_LOGIN: "login",
    AUTH_FORGOT_PASSWORD: "forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "forgot-password/finish",
    //routes for the Payment-page
    PAYMENT_PAGE: "payment/:token",
    //routes for the e-Payment application
    PAYMENT_LINKS: "",
    PAYMENT_LINKS_CREATE: "create-payment-link",
    CLIENT_BANK_ACCOUNTS_CREATE: "create",
    CLIENT_BANK_ACCOUNTS: "clients/:id/bank-accounts",
    CLIENTS: "clients",
    CLIENTS_CREATE: "create",
    MY_BANKS_ACCOUNTS: "my-banks-accounts",
    MY_BANKS_ACCOUNTS_CREATE: "create",
    TEMPLATES: "templates",
    TEMPLATES_CREATE: "create",
    SETTINGS: "settings",
    // routes for my account
    MY_ACCOUNT: "my-account",
    MY_ACCOUNT_CHANGE_EMAIL: "change-email",
    MY_ACCOUNT_CHANGE_PASSWORD: "change-password",
};

const routes = {
    // Auth routes
    AUTH_LOGIN: "/login",
    AUTH_FORGOT_PASSWORD: "/forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "/forgot-password/finish",
    //routes for the Payment-page
    PAYMENT_PAGE: "/payment/:token",
    //routes for the e-Payment application
    PAYMENT_LINKS: "/",
    PAYMENT_LINKS_CREATE: "/create-payment-link",
    CLIENT_BANK_ACCOUNTS_CREATE: "/clients/:id/bank-accounts/create",
    CLIENT_BANK_ACCOUNTS: "/clients/:id/bank-accounts",
    CLIENTS: "/clients",
    CLIENTS_CREATE: "/clients/create",
    MY_BANKS_ACCOUNTS: "/my-banks-accounts",
    MY_BANKS_ACCOUNTS_CREATE: "/my-banks-accounts/create",
    TEMPLATES: "/templates",
    TEMPLATES_CREATE: "/templates/create",
    SETTINGS: "settings",
    // routes for my account
    MY_ACCOUNT: "/my-account",
    MY_ACCOUNT_CHANGE_EMAIL: "/my-account/change-email",
    MY_ACCOUNT_CHANGE_PASSWORD: "/my-account/change-password",
};

export default routes;
