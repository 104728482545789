import { useStore } from "react-redux";
import { createElement } from "react";

export default function useCreatePublicLoader(i18n) {
    const store = useStore();

    return function createPublicLazyLoader(lazyComponentLoader) {
        return async function () {
            const ref = {
                current: null,
            };
            return {
                Component: (props) => createElement(ref.current, props),
                loader: async (opts) => {
                    if (opts.request.signal.aborted) {
                        return null;
                    }

                    let loaderResult = null;
                    const result = await lazyComponentLoader();
                    if (result.loader) {
                        loaderResult = await result.loader(opts, {
                            i18n,
                            dispatch: store.dispatch,
                            getState: store.getState,
                        });
                    }

                    ref.current = result.default;
                    return loaderResult;
                },
            };
        };
    };
}
