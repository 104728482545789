import { createReducer } from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";
import { callPrivateApiAction, callPublicApiAction } from "./api";
import handleReportError from "../features/errors/handleReportError";

/*******************
 * ACTION TYPES
 *******************/
const actionTypes = {
    SET_IDENTITY: "auth/SET_IDENTITY",
    UPDATE_EMAIL: "auth/UPDATE_EMAIL",
};

/*******************
 * REDUCER
 *******************/
export default createReducer({ identity: null }, (builder) => {
    builder.addCase(actionTypes.SET_IDENTITY, (state, action) => {
        return { ...state, identity: action.payload };
    });
    builder.addCase(actionTypes.UPDATE_EMAIL, (state, action) => {
        return { ...state, identity: { ...state.identity, email: action.payload } };
    });
});

/*******************
 * ACTIONS
 *******************/
export const authActions = {
    loginApi({ email, password, rememberMe }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/auth/login",
            {
                email,
                password,
                rememberMe,
            },
            { signal: abortControllerSignal },
        );
    },

    /**
     * Used by the login & signup flows but also partially by the getIdentify flow
     */
    setIdentity(payload) {
        return (dispatch) => {
            dispatch({
                type: actionTypes.SET_IDENTITY,
                payload: payload,
            });
        };
    },

    getIdentity() {
        return async (dispatch) => {
            const { body } = await dispatch(callPublicApiAction("GET", "/v1/auth/identity"));
            dispatch(this.setIdentity(body));

            return body;
        };
    },

    logoutApi: function () {
        return (dispatch) => {
            try {
                dispatch(callPrivateApiAction("POST", "/v1/auth/logout"));
                dispatch(this.setIdentity(null));
            } catch (error) {
                handleReportError(error);
            }
        };
    },

    startForgotPasswordApi({ email, locale }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/auth/reset-password/start",
            {
                email,
                locale,
            },
            { signal: abortControllerSignal },
        );
    },

    finishForgotPasswordApi({ email, code, password, confirmPassword }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/auth/reset-password/finish",
            {
                email,
                code,
                password,
                confirmPassword,
            },
            { signal: abortControllerSignal },
        );
    },

    changePasswordApi({ currentPassword, password, confirmPassword }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/auth/change-password",
            {
                currentPassword,
                password,
                confirmPassword,
            },
            { signal: abortControllerSignal },
        );
    },

    startChangeEmailApi({ password, email }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/auth/change-email/start",
            {
                password,
                email,
            },
            { signal: abortControllerSignal },
        );
    },

    finishChangeEmailApi({ email, code }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/auth/change-email/finish",
            {
                email,
                code,
            },
            { signal: abortControllerSignal },
        );
    },
    updateEmail: function (email) {
        return { type: actionTypes.UPDATE_EMAIL, payload: email };
    },
    updateMyAccountApi({ fname, lname, locale }, abortControllerSignal) {
        return async (dispatch, getState) => {
            const result = await dispatch(
                callPrivateApiAction(
                    "POST",
                    "/v1/auth/update-account",
                    {
                        fname,
                        lname,
                        locale,
                    },
                    { signal: abortControllerSignal },
                ),
            );

            const identity = getIdentity(getState());
            dispatch({
                type: actionTypes.SET_IDENTITY,
                payload: { ...identity, fname, lname },
            });

            return result;
        };
    },
};

/*******************
 * SELECTORS
 *******************/
const getIdentity = memoize((state) => state.auth.identity);
const hasIdentity = memoize((state) => !!getIdentity(state));

export const authSelectors = {
    hasIdentity,
    fname: memoize((state) => getIdentity(state).fname),
    lname: memoize((state) => getIdentity(state).lname),
    email: memoize((state) => getIdentity(state).email),
    preferredLocale: memoize((state) => getIdentity(state).locale),
};
