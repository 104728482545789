import { captureException } from "@sentry/react";
import { CanceledError } from "axios";

export default function handleReportError(error) {
    console.error(error);

    if (error.name === "ChunkLoadError" || error.name === "SyntaxError") {
        window.location.reload();
        return;
    }

    if (error instanceof CanceledError || (error.response && [401, 403, 503].includes(error.response.status))) {
        return;
    }

    captureException(error);
}
