import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelectors } from "../../redux/auth";
import Trans from "../intl/Trans";
import PrivateLayout from "../app/Layout/PrivateLayout";
import PublicLayout from "../app/Layout/PublicLayout";
import PrivatePage from "../app/Page/PrivatePage";
import { useLocation } from "react-router-dom";
import routes from "../router/routes";
import Link from "../../vendor/router/Link";

NotFoundErrorPage.propTypes = {};

/**
 * This page is rendered as a standalone page when the router doesn't match a router
 * but also by the RouterErrorHandler. when the app fails with a ReactRouterLoader error
 * and the error has a status (or a response.status) code of 404.
 */
export default function NotFoundErrorPage() {
    /**
     * --------------------
     * Get the identity in order to ensure we can link to the dashboard or login
     * --------------------
     */
    const hasIdentity = useSelector(authSelectors.hasIdentity);

    const location = useLocation();
    /**
     * --------------------
     * Get the translator for the page title
     * --------------------
     */
    const { t } = useTranslation();

    const locationIncludesPayment = location.pathname.includes("payment");

    const Layout = hasIdentity ? (locationIncludesPayment ? PublicLayout : PrivateLayout) : PublicLayout;

    return (
        <Layout>
            <PrivatePage title={t("PrivatePage not found")}>
                <div className="px-4 py-10 text-center sm:px-6 lg:px-8">
                    <h1 className="block text-7xl font-bold text-black dark:text-gray-50 sm:text-9xl">404</h1>
                    <h1 className="block text-2xl font-bold text-gray-50"></h1>
                    <p className="mt-3 text-gray-600 dark:text-gray-400">
                        <Trans>Oops, ceva a mers greșit.</Trans>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <Trans>Ne pare rău, nu am putut găsi pagina căutată</Trans>
                    </p>
                    {!locationIncludesPayment && (
                        <>
                            <div className="mt-5 flex flex-col items-center justify-center gap-2 sm:flex-row sm:gap-3">
                                {hasIdentity && (
                                    <Link
                                        to={routes.PAYMENT_LINKS}
                                        className="inline-flex w-full items-center justify-center gap-2 rounded-md border border-transparent px-4 py-3 text-sm font-semibold text-blue-500 ring-offset-white transition-all hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:ring-offset-slate-900 sm:w-auto">
                                        <svg
                                            className="h-2.5 w-2.5"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none">
                                            <path
                                                d="M11.2792 1.64001L5.63273 7.28646C5.43747 7.48172 5.43747 7.79831 5.63273 7.99357L11.2792 13.64"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"></path>
                                        </svg>
                                        <Trans>Înapoi la tabloul de bord</Trans>
                                    </Link>
                                )}
                            </div>
                            {!hasIdentity && (
                                <Link
                                    to={routes.AUTH_LOGIN}
                                    className="inline-flex w-full items-center justify-center gap-2 rounded-md border border-transparent px-4 py-3 text-sm font-semibold text-blue-500 ring-offset-white transition-all hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:ring-offset-slate-900 sm:w-auto">
                                    <svg className="h-2.5 w-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M11.2792 1.64001L5.63273 7.28646C5.43747 7.48172 5.43747 7.79831 5.63273 7.99357L11.2792 13.64"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"></path>
                                    </svg>
                                    <Trans>Înapoi la autentificare</Trans>
                                </Link>
                            )}
                        </>
                    )}
                </div>
            </PrivatePage>
        </Layout>
    );
}
