import React from "react";
import { Trigger as DropdownPrimitiveTrigger } from "@radix-ui/react-dropdown-menu";
import PropTypes from "prop-types";

DropdownTrigger.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default function DropdownTrigger({ children, ...props }) {
    return (
        <DropdownPrimitiveTrigger asChild {...props}>
            {children}
        </DropdownPrimitiveTrigger>
    );
}
