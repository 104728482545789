import React from "react";
import { Item as DropdownPrimitiveItem } from "@radix-ui/react-dropdown-menu";
import PropTypes from "prop-types";
import cc from "classcat";
import { twMerge } from "tailwind-merge";

DropdownItem.propTypes = {
    children: PropTypes.node.isRequired,
    asChild: PropTypes.bool,
    onSelect: PropTypes.func,
    className: PropTypes.string,
};

export default function DropdownItem({ children, onSelect, className }) {
    return (
        <DropdownPrimitiveItem
            asChild
            onSelect={onSelect}
            className={twMerge(
                cc(["block", "px-4", "py-2", "text-sm", "text-gray-700", "outline-none", "cursor-pointer", className]),
            )}>
            <li>{children}</li>
        </DropdownPrimitiveItem>
    );
}
