import Trans from "../intl/Trans";
import Link from "../../vendor/router/Link";
import routes from "../router/routes";
import React from "react";
import PropTypes from "prop-types";

InternalServerErrorPage.propTypes = {
    hasIdentity: PropTypes.bool,
};

export default function InternalServerErrorPage({ hasIdentity = false }) {
    return (
        <div className="px-4 py-10 text-center sm:px-6 lg:px-8">
            <h1 className="block text-7xl font-bold text-gray-800 dark:text-white sm:text-9xl">500</h1>
            <h1 className="block text-2xl font-bold text-gray-50"></h1>
            <p className="mt-3 text-gray-600 dark:text-gray-400">
                <Trans>Ups, ceva a mers prost.</Trans>
            </p>
            <p className="text-gray-600 dark:text-gray-400">
                <Trans>Internal Server Error</Trans>
            </p>

            <div className="mt-5 flex flex-col items-center justify-center gap-2 sm:flex-row sm:gap-3">
                {hasIdentity && (
                    <Link
                        to={routes.PAYMENT_LINKS}
                        className="inline-flex w-full items-center justify-center gap-2 rounded-md border border-transparent px-4 py-3 text-sm font-semibold text-blue-500 ring-offset-white transition-all hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:ring-offset-slate-900 sm:w-auto">
                        <svg className="h-2.5 w-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M11.2792 1.64001L5.63273 7.28646C5.43747 7.48172 5.43747 7.79831 5.63273 7.99357L11.2792 13.64"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"></path>
                        </svg>
                        <Trans>Înapoi la tabloul de bord</Trans>
                    </Link>
                )}
            </div>
        </div>
    );
}
