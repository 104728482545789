import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import cacheProvider from "./cacheProvider";
import httpClientProvider from "./httpClientProvider";
import configReducer, { configActions, configSelectors } from "./../redux/config";
import authReducer from "./../redux/auth";
import listFactoryReducer from "../redux/listFactory";

// We need to export the store instance since, otherwise, hot reload will recreate the store on each reload (if we export a function)
export const thunkOptions = {};

/**
 * ------------
 * Boot store
 * ------------
 */
const reduxStore = configureStore({
    reducer: {
        config: configReducer,
        auth: authReducer,
        listFactory: listFactoryReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: { extraArgument: thunkOptions } }),
});
reduxStore.dispatch(configActions.loadConfig(process.config));

/**
 * ------------
 * Boot Sentry
 * ------------
 */
if (configSelectors.sentryEnabled(reduxStore.getState())) {
    Sentry.init({
        dsn: configSelectors.sentryDsn(reduxStore.getState()),
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        environment: configSelectors.env(reduxStore.getState()),
        tracesSampleRate: 0.1, //  Capture 100% of the transactions
        normalizeDepth: 11,

        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Session Replay
        replaysSessionSampleRate: configSelectors.sentryReplaysSessionSampleRate(reduxStore.getState()), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        enabled: configSelectors.env(reduxStore.getState()) !== "local",
    });
}

/**
 * ------------
 * Boot cache & httpClient
 * ------------
 */
thunkOptions.cache = cacheProvider();
thunkOptions.httpClient = httpClientProvider(reduxStore, process.config.app.appUrl);

export default reduxStore;
