import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useApplicationLoaderContext } from "../ApplicationLoaderContext";

PaymentPageLayout.propTypes = {};

export default function PaymentPageLayout() {
    /**
     * Hide page loader on page mount
     */
    const { setIsPageLoaderShown } = useApplicationLoaderContext();

    useEffect(() => {
        setIsPageLoaderShown(false);
        return () => setIsPageLoaderShown(true);
    }, []);

    return (
        <div className="min-h-full">
            <main>
                <div className="mx-auto max-w-4xl sm:px-6 sm:py-6 lg:px-8">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}
