import React, { useEffect } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import PropTypes from "prop-types";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../redux/auth";
import { useApplicationLoaderContext } from "../ApplicationLoaderContext";
import routes from "../../router/routes";
import useNavigate from "../../../vendor/router/useNavigate";

PrivateLayout.propTypes = {
    children: PropTypes.node,
};

export default function PrivateLayout({ children = null }) {
    const { setIsPageLoaderShown } = useApplicationLoaderContext();

    const navigate = useNavigate();
    const hasIdentity = useSelector(authSelectors.hasIdentity);

    useEffect(() => {
        setIsPageLoaderShown(false);

        return () => setIsPageLoaderShown(true);
    }, []);

    /**
     * When the user is logged out (either from Navbar > Logout or from interceptor 401 situation)
     * redirect the user to the login page.
     */
    useEffect(() => {
        if (!hasIdentity) {
            navigate(routes.AUTH_LOGIN);
        }
    }, [hasIdentity]);

    /**
     * When the user logs out we don't have any identity and the app can throw errors due to auth selectors.
     * This way we ensure that the app doesn't render anything while the user is redirected
     * to the login page.
     */
    if (!hasIdentity) {
        return null;
    }

    return (
        <>
            <div className="min-h-full">
                <Navbar />
                <main>
                    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">{children || <Outlet />}</div>
                </main>
            </div>
            <ScrollRestoration />
        </>
    );
}
